import React from "react";
import BaseModal from "./BaseModal";
import { Loading } from "../Icons";

const ConnectingModal = () => {
	return (
		<BaseModal Icon={Loading} iconClass="spin" showClose={false}>
			<h1 className="font-bold text-4xl">Almost there...</h1>
			<p className="font-normal text-sm">
				We're connecting you to the event environment.
				<br />
				This can take up to 2 minutes.
			</p>
		</BaseModal>
	);
};

export default ConnectingModal;
