export default class Identity {
	constructor(uuid, name, company, role, avatar, type, id = 0) {
		this.id = uuid;
		this.uuid = uuid;
		this.name = name;
		this.company = company;
		this.role = role;
		this.avatar = avatar;
		this.type = type;
	}
}
