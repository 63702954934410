import React from "react";

const Close = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 50 50"
		className="fill-current"
	>
		<g id="close">
			<path d="M11.47,40a1.46,1.46,0,0,1-1-2.5L37.5,10.43a1.46,1.46,0,0,1,2.07,2.07L12.5,39.57a1.44,1.44,0,0,1-1,.43Zm0,0" />
			<path d="M38.53,40a1.44,1.44,0,0,1-1-.43L10.43,12.5a1.46,1.46,0,0,1,2.07-2.07L39.57,37.5a1.46,1.46,0,0,1-1,2.5Zm0,0" />
		</g>
	</svg>
);
const Loading = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 50 50"
		className={`fill-current ${props.className}`}
	>
		<g id="loading">
			<path d="M29.42,13.81a3.7,3.7,0,0,1-7.37,0,3.7,3.7,0,0,1,7.37,0Zm0,0" />
			<path d="M28,37.56a2.22,2.22,0,1,1-2.22-2.05A2.14,2.14,0,0,1,28,37.56Zm0,0" />
			<path d="M20,17.28a3.2,3.2,0,0,1-3.31,3.08,3.2,3.2,0,0,1-3.31-3.08,3.2,3.2,0,0,1,3.31-3.07A3.2,3.2,0,0,1,20,17.28Zm0,0" />
			<path d="M36.62,34.08a1.84,1.84,0,1,1-1.84-1.71,1.79,1.79,0,0,1,1.84,1.71Zm0,0" />
			<path d="M15.89,25.69A2.85,2.85,0,0,1,13,28.42,2.74,2.74,0,1,1,13,23a2.85,2.85,0,0,1,2.94,2.74Zm0,0" />
			<path d="M40,25.68a1.48,1.48,0,1,1-1.48-1.36A1.42,1.42,0,0,1,40,25.68Zm0,0" />
			<path d="M14.87,32.39a2.28,2.28,0,0,0,0,3.39,2.72,2.72,0,0,0,3.64,0,2.28,2.28,0,0,0,0-3.39,2.7,2.7,0,0,0-3.64,0Zm0,0" />
			<path d="M35.88,17.28a1.1,1.1,0,1,1-1.1-1,1.06,1.06,0,0,1,1.1,1Zm0,0" />
		</g>
	</svg>
);
const User = (props) => (
	<svg
		aria-hidden="true"
		focusable="false"
		data-prefix="fas"
		data-icon="user-circle"
		className={`svg-inline--fa fa-user-circle fa-w-16 fill-current ${props.className}`}
		role="img"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 496 512"
	>
		<path
			fill="currentColor"
			d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"
		></path>
	</svg>
);

export { Close, Loading, User };
