import React, { useEffect, useState } from "react";
import { Close } from "../Icons";

const BaseModal = ({
	size = "xl",
	buttons = 0,
	children,
	modalOpen = true,
	closeCallback,
	className = "",
	Icon,
	iconClass,
	confirmCallback,
	confirmText,
	cancelCallback,
	cancelText,
	extraButtons,
	showClose = true,
}) => {
	return (
		modalOpen && (
			<div className="modal-container">
				<div className={`modal-panel ${className} max-w-${size}`}>
					<div className="modal-panel__top">
						<div className={`modal-panel__icon`}>
							{Icon && <Icon className={`icon ${iconClass}`} />}
						</div>
						{showClose && (
							<div
								className="modal-panel__close"
								onClick={(e) => {
									if (closeCallback) closeCallback();
								}}
							>
								<Close />
							</div>
						)}
					</div>
					<div className="modal-panel__content">{children}</div>
					{!!buttons > 0 && (
						<div className="modal-panel__actions flex">
							{!!(buttons & OK) && (
								<button
									className="modal-panel__btn modal-panel__btn--accept"
									onClick={(e) => {
										e.preventDefault();
										if (confirmCallback) confirmCallback(true);
									}}
								>
									{confirmText}
								</button>
							)}
							{!!(buttons & CANCEL) && (
								<button
									className="modal-panel__btn modal-panel__btn--decline"
									onClick={(e) => {
										e.preventDefault();
										if (cancelCallback) cancelCallback(false);
									}}
								>
									{cancelText}
								</button>
							)}
							{extraButtons && extraButtons()}
						</div>
					)}
				</div>
			</div>
		)
	);
};

export default BaseModal;

export const OK = 1;
export const CANCEL = 2;
