import React from "react";

const Shape1 = (props) => (
	<svg
		{...props}
		xmlns="http://www.w3.org/2000/svg"
		x="0"
		y="0"
		enableBackground="new 0 0 913 880"
		version="1"
		viewBox="0 0 913 880"
		xmlSpace="preserve"
	>
		<path
			fill="currentColor"
			d="M75.4 818.5c72.4 78.3 214.8 84.3 290.6 4 49.9-52.9 73-98.4 127.5-133.5 62-39.9 113.2-34.2 185.5 5.8 59 32.6 171.1 36.5 214.9-40 45.5-79.5-1.5-145.5-28.9-184.6-27.4-39.1-90.2-116.2-45-212.5C865.3 161.5 857.4 43 762.9 9.9c-104.6-36.6-201.4 27-223.3 210.2-17.5 146.4-155.1 120.5-267.7 127.4-106 6.5-210.4 71-245 147.2-33.4 73.4-52.8 214.1 48.5 323.8z"
		></path>
	</svg>
);

const Shape2 = (props) => (
	<svg
		{...props}
		xmlns="http://www.w3.org/2000/svg"
		x="0"
		y="0"
		enableBackground="new 0 0 858 825"
		version="1"
		viewBox="0 0 858 825"
		xmlSpace="preserve"
	>
		<path
			fill="currentColor"
			d="M64.1 372.4c63.7 103 61.2 164.8 48.9 266.6-12.2 101.8 8.3 146.2 48.9 171 52.5 32 129.8 14.2 173.3-65.8S449 631 543.4 645.9c135.1 21.3 230.5-9.2 280.8-98.7 50.3-89.5 53.4-252.8-52.8-319.8-106.1-67-201.1-5.4-362.8-135.7C246.8-38.7 110.8-11.4 47.1 59-8 119.8-29 221.8 64.1 372.4z"
		></path>
	</svg>
);

export { Shape1, Shape2 };
