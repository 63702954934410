import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import useUser from "../store/User";

import FurioosPlayer from "../components/FurioosPlayer";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

const PrivateRoute = ({ component: Component, ...rest }) => {
	const [user, userActions] = useUser();

	const FSHandle = useFullScreenHandle();

	return (
		<>
			{user.identity.id != undefined ? (
				<FullScreen handle={FSHandle}>
					<FurioosPlayer />

					<div
						onClick={FSHandle.enter}
						className="text-white bg-black bg-opacity-50 hover:bg-opacity-90 cursor-pointer text-sm font-medium absolute z-50 left-0 bottom-0 ml-2 mb-2 p-2 rounded-md"
					>
						Fullscreen
					</div>
				</FullScreen>
			) : (
				<Redirect path="/" />
			)}
		</>
	);
};

export default PrivateRoute;
