import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

import NotFoundPage from "../components/NotFoundPage";

import LoginPage from "../components/LoginPage";
import FurioosPlayer from "../components/FurioosPlayer";

const AppRouter = (props) => {
	return (
		<Router basename="/">
			<div className="w-full h-full">
				<Switch>
					<LoginPage {...props} path="/" exact />
					<PrivateRoute path="/auth" exact={true} component={FurioosPlayer} />
				</Switch>
			</div>
		</Router>
	);
};

export default AppRouter;
