import React, { useState, useEffect } from "react";
import useUser, { LOGIN_STATES } from "../store/User";
import ConnectingModal from "./modals/ConnectingModal";
import { withRouter, Redirect } from "react-router-dom";

import LoginGraphic from "./LoginGraphic";
import { Shape1, Shape2 } from "./Shapes";
import LoginForm from "./LoginForm";

const LoginPage = withRouter((props) => {
	const [showRelogin, setShowRelogin] = useState(false);
	const [user, userActions] = useUser();

	useEffect(() => {
		if (props.user) userActions.rejoinEvent(props.user);
		//if (localStorage.getItem("OGGI-userId"))
		//	userActions.rejoinEvent(localStorage.getItem("OGGI-userId"));
	}, []);

	const handleLogin = (username, pass) => {
		userActions.joinEvent(username, pass);
	};
	const handleReLogin = () => {
		userActions.rejoinEvent(localStorage.getItem("OGGI-userId"));
	};

	// Run on startup
	/*
	useEffect(() => {
		userActions.initUser();

		const storedUserId = localStorage.getItem("OGGI-userId");
		if (storedUserId) setShowRelogin(true);
	}, []);
	*/
	/*
	useEffect(() => {
		if (user.loginStatus == LOGIN_STATES.SUCCESS){
			history.replace({pathname:"/");
		}
	}, [user]);
*/
	return (
		<div className="flex items-center p-5 lg:p-20 overflow-hidden relative md:h-screen">
			<Shape2
				style={{ color: "#d5bfd2", marginTop: "-9%", marginLeft: "-5%" }}
				className="absolute z-0 top-0 left-0 w-1/3 "
			/>
			<Shape1
				style={{ color: "#302e76", marginLeft: "-18%", marginTop: "-20%" }}
				className="absolute z-0 top-0 left-0 w-2/3 animate-slow-wiggle"
			/>

			<div
				className="flex-1 rounded-3xl
			 bg-white shadow-xl text-gray-800 
			 relative md:flex text-center md:text-left
			  overflow-hidden
			  md:py-16
			  w-full
			  max-w-6xl mx-auto"
			>
				<Shape2
					style={{
						color: "#9ecddd",
						transform: "scale(-1.2,1.2) rotate(-75deg)",
						marginRight: "10%",
						marginBottom: "-11%",
					}}
					className="absolute opacity-20 rotate-45 right-0 bottom-0 w-1/2"
				/>
				<Shape2
					style={{
						color: "#e489d8",
						marginRight: "-18%",
						bottom: 0,
						marginTop: "-5%",
						transform: "scale(1.2) rotate(62deg)",
						marginBottom: "18%",
					}}
					className="absolute opacity-20 rotate-90 right-0 w-1/2"
				/>

				<div className="w-full p-10 pb-0 md:w-1/2 md:pl-15 md:pr-16 xl:pl-24 xl:pr-28 2xl:pl-32 2xl:pr-32 z-10">
					{user.loginStatus == LOGIN_STATES.INITIAL && (
						<LoginForm
							handleReLogin={handleReLogin}
							handleLogin={handleLogin}
						/>
					)}

					{user.loginStatus == LOGIN_STATES.LOADING && <ConnectingModal />}

					{user.loginStatus == LOGIN_STATES.SUCCESS && (
						<Redirect to={`/auth`} />
					)}
				</div>
				<div className="w-full md:w-1/2 z-10 md:self-end">
					<LoginGraphic />
				</div>
			</div>
		</div>
	);
});
export default LoginPage;
