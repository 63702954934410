export const sendMessage = (store, message) => {
	store.setState({
		...store.state,
		message,
	});
};
export const clearMessage = (store) => {
	store.setState({
		...store.state,
		message: "",
	});
};
