import React from "react";
import globalHook from "use-global-hook";

import * as actions from "../actions/User";
import Identity from "../types/Identity";

export const LOGIN_STATES = {
	INITIAL: "INITIAL",
	LOADING: "LOADING",
	NOT_FOUND: "NOT_FOUND",
	SUCCESS: "SUCCESS",
	ERROR: "ERROR",
};

const initialState = {
	identity: new Identity(),
	joinedEvent: false,
	loggedIn: false,
	loginStatus: LOGIN_STATES.INITIAL,
	eventId: "",
	config: {},
};

const useUser = globalHook(React, initialState, actions);

export default useUser;
