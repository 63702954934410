import axios from "axios";
import { LOGIN_STATES } from "../store/User";
import Identity from "../types/Identity";

export const initUser = (store) => {};

const handleResult = (store, result) => {
	if (result.data) {
		store.setState({
			identity: new Identity(result.data.id),
			eventId: result.data.eventCode,
			joinedEvent: true,
			loggedIn: true,
			loginStatus: LOGIN_STATES.SUCCESS,
			config: {
				furioosId: result.data.config.furioosId,
			},
		});

		// localStorage.setItem("OGGI-userId", result.data.id);
	}
};
const handleError = (store, err) => {
	const copyState = { ...store.state };
	if (err.response.status == "404") {
		copyState.loginStatus = LOGIN_STATES.NOT_FOUND;
		store.setState(copyState);
	} else {
		copyState.loginStatus = LOGIN_STATES.ERROR;
		store.setState(copyState);
	}
};

export const rejoinEvent = (store, userId) => {
	const copyState = { ...store.state };
	copyState.loginStatus = LOGIN_STATES.LOADING;
	store.setState(copyState);
	axios
		.post("https://virtual-events.realitymatters.studio/api/connect.php", {
			action: "profile",
			id: userId,
		})
		.then((result) => {
			handleResult(store, result);
		})
		.catch((err) => {
			handleError(store, err);
		});
};
export const joinEvent = (store, user, pass) => {
	const copyState = { ...store.state };
	copyState.loginStatus = LOGIN_STATES.LOADING;
	store.setState(copyState);
	axios
		.post("https://virtual-events.realitymatters.studio/api/connect.php", {
			action: "login",
			user: user,
			pass: pass,
		})
		.then((result) => {
			handleResult(store, result);
		})
		.catch((err) => {
			handleError(store, err);
		});
};
