const Graphic = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		x="0"
		y="0"
		enableBackground="new 0 0 1414.9 852.1"
		version="1"
		viewBox="0 0 1414.9 852.1"
		xmlSpace="preserve"
	>
		<linearGradient
			id="SVGID_1_"
			x1="64.927"
			x2="1369.397"
			y1="416.846"
			y2="416.846"
			gradientUnits="userSpaceOnUse"
		>
			<stop offset="0" stopColor="#F16B8A"></stop>
			<stop offset="1" stopColor="#ED288B"></stop>
		</linearGradient>
		<path
			fill="url(#SVGID_1_)"
			d="M750.9 69.9c-50.5-22.3-112-2.6-164.1 19.8-40.1 17.2-82.4 36.6-126.7 37.7-38.7 1-76.6-9.8-115.9-7.7-25.6 1.4-53.7 1.9-75.4 15-36.5 21.9-67.5 48.1-63.7 89.7 4.1 45.8 11.9 95.3-2.4 140.2-7 22-19.4 42.7-36.1 60-15.8 16.4-35.1 29.7-53 44.3-17.8 14.6-34.6 31.2-43.2 51.4-8.6 20.3-7.6 45 7.8 61.7 18 19.6 52.4 26.1 62.5 49.9 4.3 10.3 2.9 21.6 3.6 32.6 3.9 66.9 87.1 101.9 151.9 107.8 81.9 7.4 161.4-18.7 240.1-36.8 168.6-38.7 352-41.7 522.7-8.4 54.5 10.6 108.9 25 165.3 24.2 56.4-.8 116.9-21.1 136.9-60.1 46.4-90.6-117.1-153.3-93.4-253.2 8.2-34.5 26.9-65.7 34.5-100.3 15.7-71.5-24.6-151.4-91-180.6-60.4-26.6-129.6-12.9-194.7-2.4-65 10.5-137.8 15.5-190.7-24.2-22.1-16.5-38.9-39.7-62.4-54.1-4.2-2.5-8.4-4.6-12.6-6.5z"
			opacity="0.06"
		></path>
		<g opacity="0.08">
			<linearGradient
				id="SVGID_2_"
				x1="2250.343"
				x2="2744.183"
				y1="854.538"
				y2="854.538"
				gradientTransform="rotate(-27.541 785.611 3453.914)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#B080B8"></stop>
				<stop offset="1" stopColor="#783B87"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_2_)"
				d="M895.9 497.6c1.9 1.9 3.5 4.1 4.8 6.4 202.6-222.1 407.8-274.5 410-275.1l-1.9-7.7c-2.2.6-209 53.5-412.9 276.4z"
			></path>
			<linearGradient
				id="SVGID_3_"
				x1="2650.303"
				x2="2734.162"
				y1="802.33"
				y2="802.33"
				gradientTransform="rotate(-27.541 785.611 3453.914)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#B080B8"></stop>
				<stop offset="1" stopColor="#783B87"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_3_)"
				d="M1222.8 257.1s46.4 2.4 53.8-75.8c-1.5.9-83.3 45.9-53.8 75.8z"
			></path>
			<linearGradient
				id="SVGID_4_"
				x1="2734.161"
				x2="2734.195"
				y1="774.2"
				y2="774.2"
				gradientTransform="rotate(-27.541 785.611 3453.914)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#B080B8"></stop>
				<stop offset="1" stopColor="#783B87"></stop>
			</linearGradient>
			<path fill="url(#SVGID_4_)" d="M1274.3 176.9s.1 0 0 0z"></path>
			<linearGradient
				id="SVGID_5_"
				x1="2650.539"
				x2="2717.626"
				y1="855.653"
				y2="855.653"
				gradientTransform="rotate(-27.541 785.611 3453.914)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#B080B8"></stop>
				<stop offset="1" stopColor="#783B87"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_5_)"
				d="M1224.2 260.8s12.6 44.7 89.1 26.5c-1.4-1-70.3-63.9-89.1-26.5z"
			></path>
			<linearGradient
				id="SVGID_6_"
				x1="2715.797"
				x2="2715.811"
				y1="892.644"
				y2="892.644"
				gradientTransform="rotate(-27.541 785.611 3453.914)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#B080B8"></stop>
				<stop offset="1" stopColor="#783B87"></stop>
			</linearGradient>
			<path fill="url(#SVGID_6_)" d="M1312.8 290.4z"></path>
			<linearGradient
				id="SVGID_7_"
				x1="2563.068"
				x2="2643.633"
				y1="780.102"
				y2="780.102"
				gradientTransform="rotate(-27.541 785.611 3453.914)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#B080B8"></stop>
				<stop offset="1" stopColor="#783B87"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_7_)"
				d="M1148.5 294.1s52.6-15.7 29.7-105.7c-1.3 1.6-74.3 84.2-29.7 105.7z"
			></path>
			<linearGradient
				id="SVGID_8_"
				x1="2648.521"
				x2="2648.554"
				y1="738.061"
				y2="738.061"
				gradientTransform="rotate(-27.541 785.611 3453.914)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#B080B8"></stop>
				<stop offset="1" stopColor="#783B87"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_8_)"
				d="M1181.7 184.5c0-.1 0-.1 0 0 0-.1 0-.1 0 0z"
			></path>
			<linearGradient
				id="SVGID_9_"
				x1="2566.359"
				x2="2645.626"
				y1="860.087"
				y2="860.087"
				gradientTransform="rotate(-27.541 785.611 3453.914)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#B080B8"></stop>
				<stop offset="1" stopColor="#783B87"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_9_)"
				d="M1149.1 298.8s14.9 52.8 105.3 31.4c-1.7-1.3-83.1-75.7-105.3-31.4z"
			></path>
			<linearGradient
				id="SVGID_10_"
				x1="2646.236"
				x2="2646.257"
				y1="905.183"
				y2="905.183"
				gradientTransform="rotate(-27.541 785.611 3453.914)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#B080B8"></stop>
				<stop offset="1" stopColor="#783B87"></stop>
			</linearGradient>
			<path fill="url(#SVGID_10_)" d="M1256.9 333.7s.1 0 0 0z"></path>
			<linearGradient
				id="SVGID_11_"
				x1="2486.359"
				x2="2566.923"
				y1="785.699"
				y2="785.699"
				gradientTransform="rotate(-27.541 785.611 3453.914)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#B080B8"></stop>
				<stop offset="1" stopColor="#783B87"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_11_)"
				d="M1083.1 334.5s52.6-15.7 29.7-105.7c-1.3 1.7-74.4 84.2-29.7 105.7z"
			></path>
			<linearGradient
				id="SVGID_12_"
				x1="2566.923"
				x2="2566.958"
				y1="745.489"
				y2="745.489"
				gradientTransform="rotate(-27.541 785.611 3453.914)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#B080B8"></stop>
				<stop offset="1" stopColor="#783B87"></stop>
			</linearGradient>
			<path fill="url(#SVGID_12_)" d="M1112.7 228.8c.1-.1.1-.1 0 0z"></path>
			<linearGradient
				id="SVGID_13_"
				x1="2368.286"
				x2="2368.307"
				y1="786.806"
				y2="786.806"
				gradientTransform="rotate(-27.541 785.611 3453.914)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#B080B8"></stop>
				<stop offset="1" stopColor="#783B87"></stop>
			</linearGradient>
			<path fill="url(#SVGID_13_)" d="M955.7 357.2z"></path>
			<linearGradient
				id="SVGID_14_"
				x1="2322.524"
				x2="2368.356"
				y1="822.561"
				y2="822.561"
				gradientTransform="rotate(-27.541 785.611 3453.914)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#B080B8"></stop>
				<stop offset="1" stopColor="#783B87"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_14_)"
				d="M954.6 438.5s34.6-21.3 1.1-81.2c-.5 1.4-37.1 74.3-1.1 81.2z"
			></path>
			<linearGradient
				id="SVGID_15_"
				x1="2485.373"
				x2="2564.64"
				y1="871.792"
				y2="871.792"
				gradientTransform="rotate(-27.541 785.611 3453.914)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#B080B8"></stop>
				<stop offset="1" stopColor="#783B87"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_15_)"
				d="M1082.7 346.6s14.9 52.8 105.3 31.4c-1.7-1.3-83.1-75.7-105.3-31.4z"
			></path>
			<linearGradient
				id="SVGID_16_"
				x1="2564.64"
				x2="2564.66"
				y1="912.611"
				y2="912.611"
				gradientTransform="rotate(-27.541 785.611 3453.914)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#B080B8"></stop>
				<stop offset="1" stopColor="#783B87"></stop>
			</linearGradient>
			<path fill="url(#SVGID_16_)" d="M1188 378z"></path>
			<linearGradient
				id="SVGID_17_"
				x1="2482.224"
				x2="2482.261"
				y1="738.511"
				y2="738.511"
				gradientTransform="rotate(-27.541 785.611 3453.914)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#B080B8"></stop>
				<stop offset="1" stopColor="#783B87"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_17_)"
				d="M1034.4 261.8c0-.1.1-.1 0 0 0-.1 0-.1 0 0z"
			></path>
			<linearGradient
				id="SVGID_18_"
				x1="2398.34"
				x2="2482.224"
				y1="790.428"
				y2="790.428"
				gradientTransform="rotate(-27.541 785.611 3453.914)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#B080B8"></stop>
				<stop offset="1" stopColor="#783B87"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_18_)"
				d="M1015.7 388.6s58.6-26 18.8-126.8c-1.3 2.1-73.7 108.5-18.8 126.8z"
			></path>
			<linearGradient
				id="SVGID_19_"
				x1="2402.369"
				x2="2504.969"
				y1="884.214"
				y2="884.214"
				gradientTransform="rotate(-27.541 785.611 3453.914)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#B080B8"></stop>
				<stop offset="1" stopColor="#783B87"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_19_)"
				d="M1015.1 397.4s25.1 59 126.5 20.8c-2.1-1.2-107.3-75.3-126.5-20.8z"
			></path>
			<linearGradient
				id="SVGID_20_"
				x1="2504.97"
				x2="2504.998"
				y1="926.845"
				y2="926.845"
				gradientTransform="rotate(-27.541 785.611 3453.914)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#B080B8"></stop>
				<stop offset="1" stopColor="#783B87"></stop>
			</linearGradient>
			<path fill="url(#SVGID_20_)" d="M1141.7 418.2z"></path>
			<linearGradient
				id="SVGID_21_"
				x1="2329.252"
				x2="2399.405"
				y1="888.916"
				y2="888.916"
				gradientTransform="rotate(-27.541 785.611 3453.914)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#B080B8"></stop>
				<stop offset="1" stopColor="#783B87"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_21_)"
				d="M957.5 445s17.1 40.3 86.5 14.2c-1.5-.8-73.4-51.5-86.5-14.2z"
			></path>
			<linearGradient
				id="SVGID_22_"
				x1="2399.405"
				x2="2399.425"
				y1="918.065"
				y2="918.065"
				gradientTransform="rotate(-27.541 785.611 3453.914)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#B080B8"></stop>
				<stop offset="1" stopColor="#783B87"></stop>
			</linearGradient>
			<path fill="url(#SVGID_22_)" d="M1044 459.2c0 .1 0 .1 0 0z"></path>
			<linearGradient
				id="SVGID_23_"
				x1="2708.354"
				x2="2791.57"
				y1="833.85"
				y2="833.85"
				gradientTransform="rotate(-27.541 785.611 3453.914)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#B080B8"></stop>
				<stop offset="1" stopColor="#783B87"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_23_)"
				d="M1276.8 237.5s32 26.7 79.7-23.9l.3-2.7c-13.1-1.5-84.9-8.8-80 26.6z"
			></path>
		</g>
		<g opacity="0.08">
			<path
				fill="#2F2E75"
				d="M403.4 471.7c-25.2-28.5-44.8-62.1-63.7-94.7-4.9-8.4-9.7-16.7-14.7-25-23.1-38.5-52.2-81.7-93.5-109.7-44.4-30.1-99.2-36.8-139.5-16.8l2.8 5.8c38.3-18.9 90.5-12.5 133.1 16.4 40.2 27.3 68.9 69.7 91.6 107.7 4.9 8.3 9.8 16.6 14.6 24.9 19.1 32.8 38.9 66.8 64.5 95.7 37 41.9 81.6 65.9 125.4 67.7l.3-6.4c-56.8-2.4-100.1-42.1-120.9-65.6z"
			></path>
			<path
				fill="#2F2E75"
				d="M73.4 234.4c2.5 21.3 40.2-13.5 40.2-13.5s-42.7-7.8-40.2 13.5zM124.9 247.1c10.8 20.2 34.5-29.2 34.5-29.2s-45.4 9-34.5 29.2zM384.6 508.8c.1 22.9 44.2-9.7 44.2-9.7s-44.3-13.2-44.2 9.7z"
			></path>
			<path
				fill="#2F2E75"
				d="M133.7 181.2c-21.5 7.9 24.1 38.3 24.1 38.3s-2.6-46.1-24.1-38.3zM442 456.2c-19.8-11.5-14.2 42.9-14.2 42.9s34-31.4 14.2-42.9zM212.9 177.9c-29.8-4.6 3.4 59.5 3.4 59.5s26.5-54.9-3.4-59.5zM410.9 405.1c-25.9-15.4-19.1 56.5-19.1 56.5s45-41.1 19.1-56.5zM297.8 201.2C260.1 179.3 271 283 271 283s64.5-59.9 26.8-81.8zM340.8 259.9C303.1 238 314 341.7 314 341.7s64.4-59.9 26.8-81.8zM382 329.2c-37.7-21.9-26.8 81.8-26.8 81.8s64.5-59.9 26.8-81.8z"
			></path>
			<path
				fill="#2F2E75"
				d="M190.8 312.2c6.5 43.1 80.6-30.3 80.6-30.3s-87.1-12.8-80.6 30.3zM235.2 369.3c6.5 43.1 80.6-30.3 80.6-30.3s-87.1-12.8-80.6 30.3zM271.3 413.2c-7.4 43 86-3.5 86-3.5s-78.6-39.5-86 3.5zM166.4 266.3c9.9 28.5 51-30.8 51-30.8s-60.9 2.2-51 30.8zM332.2 458.2c-8.2 29.1 59.5 3.8 59.5 3.8s-51.3-32.9-59.5-3.8z"
			></path>
		</g>
		<g opacity="0.18">
			<linearGradient
				id="SVGID_24_"
				x1="919.344"
				x2="1441.374"
				y1="570.57"
				y2="570.57"
				gradientTransform="matrix(1.1876 .2817 -.2732 1.1419 -908.955 -356.239)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#70CDE2"></stop>
				<stop offset="0.238" stopColor="#52C2D5"></stop>
				<stop offset="0.741" stopColor="#0AB"></stop>
				<stop offset="1" stopColor="#00A0AE"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_24_)"
				d="M573.7 686.7c-54.3-40.2-101.1-87.6-137.8-139.6-10-14.1-22.2-30.4-41.1-32.5-25.4-2.8-39.7 21.3-61.7 30.4-24.3 9.9-55.2.1-79.5-12.9-24.3-13-47.3-29.8-74.9-35.4-28.5-5.8-57.8 1.6-80.9 14.5-23.1 13-41 31.1-58.7 49-7.4 7.5-15 15.3-19 24.6s-3.8 20.7 3.5 29.4c12.4 14.7 37.9 14.7 57.2 9.9 19.3-4.9 38.8-13 59.2-8.8 29.1 6 44.6 33.4 54.5 57.3 9.9 23.9 22.6 51.4 50.9 60.4 28.2 9 57.1-5.6 76.2-23 18.3-16.7 33.4-35.1 66.6-25 26.1 7.9 32.3 28.7 44.9 46.5 9.1 12.9 18.1 26.1 30.5 37.1 13.8 12.3 26.9 16 46.1 17.6 47 4 92.4-12.7 120.8-41.8 9.5-9.7 14.7-23.3 9.6-36-22.5 6.4-47.4-7.6-66.4-21.7z"
			></path>
			<g>
				<path
					fill="#FFF"
					d="M612.1 723.1c-1.2-.6-2.6-.4-3.3.6-.2.3-5.2 7.2-18.6 8.3-14.3 1.1-40.1-4.6-82.4-38-8.6-6.8-16.7-13.3-24.5-19.6-66.1-53.3-106.1-85.4-149.3-55.7-41.5 28.5-65.1 9.8-107.9-24.2-3.4-2.7-7-5.5-10.6-8.4-29.6-23.2-55-33.9-80.1-33.7-27.4.2-54.4 13.6-82.6 40.8-.8.8-.7 2.1.4 2.9 1 .8 2.6.7 3.4-.1 27.3-26.4 53.2-39.3 79.2-39.6 23.7-.2 48 10.1 76.5 32.5 3.7 2.9 7.2 5.7 10.6 8.4 41.9 33.3 69.6 55.3 114.3 24.6 40-27.5 76.6 1.9 143 55.2 7.8 6.3 15.9 12.8 24.5 19.6 35.2 27.9 64.3 41.1 86.6 39.2 9.4-.8 15.4-4.2 18.6-6.8 2.1-1.7 3.1-3.1 3.2-3.3.6-.8.2-2-1-2.7z"
				></path>
			</g>
		</g>
		<path fill="#1B144F" d="M604.4 758.2H850.3V832H604.4z"></path>
		<path
			fill="#2F2E75"
			d="M893.8 821.2H562.5c-2.2 0-3.9 2.7-3.9 6.1v9.3c0 3.4 1.7 6.1 3.9 6.1h331.2c2.2 0 3.9-2.7 3.9-6.1v-9.3c.1-3.3-1.7-6.1-3.8-6.1z"
		></path>
		<g fill="#2F2E75">
			<path d="M1119.6 168.3H335.1c-14.2 0-25.8 11.6-25.8 25.8v535.1c0 14.2 11.6 29.3 25.7 29.3h784.5c14.2 0 25.8-15.6 25.8-29.8V194.1c.1-14.2-11.5-25.8-25.7-25.8z"></path>
			<path d="M309.3 744.4c0 10 7.2 18.2 16 18.2h804.1c8.8 0 16-8.2 16-18.2v-15.8H309.3v15.8z"></path>
		</g>
		<path fill="#FFF" d="M331 190.6H1123.7V712.3000000000001H331z"></path>
		<path
			fill="#2F2E75"
			d="M330.7 712.6H1124V190.3H330.7v522.3zm.6-521.7h792.1V712H331.3V190.9z"
		></path>
		<g opacity="0.48">
			<linearGradient
				id="SVGID_25_"
				x1="344.989"
				x2="1108.028"
				y1="665.106"
				y2="665.106"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#70CDE2"></stop>
				<stop offset="0.238" stopColor="#52C2D5"></stop>
				<stop offset="0.741" stopColor="#0AB"></stop>
				<stop offset="1" stopColor="#00A0AE"></stop>
			</linearGradient>
			<path fill="url(#SVGID_25_)" d="M345 629.1H1108V701.1H345z"></path>
		</g>
		<g opacity="0.38">
			<path
				fill="#FFF"
				d="M779.6 644.1H395.9c-4.5 0-8.2 2.4-8.2 5.3v.4c0 2.9 3.7 5.3 8.2 5.3h383.7c4.5 0 8.2-2.4 8.2-5.3v-.4c-.1-2.8-3.7-5.3-8.2-5.3zM542.4 667.3H395.9c-4.5 0-8.2 2.4-8.2 5.3v.4c0 2.9 3.7 5.3 8.2 5.3h146.6c4.5 0 8.2-2.4 8.2-5.3v-.4c-.1-2.9-3.8-5.3-8.3-5.3zM738.4 667.3H591.8c-4.5 0-8.2 2.4-8.2 5.3v.4c0 2.9 3.7 5.3 8.2 5.3h146.6c4.5 0 8.2-2.4 8.2-5.3v-.4c0-2.9-3.7-5.3-8.2-5.3z"
			></path>
		</g>
		<linearGradient
			id="SVGID_26_"
			x1="344.989"
			x2="1107.069"
			y1="409.96"
			y2="409.96"
			gradientUnits="userSpaceOnUse"
		>
			<stop offset="0" stopColor="#F9F183"></stop>
			<stop offset="0.33" stopColor="#F9DC6B"></stop>
			<stop offset="1" stopColor="#F7B02F"></stop>
		</linearGradient>
		<path
			fill="url(#SVGID_26_)"
			d="M345 204.3H1107.1V615.5H345z"
			opacity="0.06"
		></path>
		<path
			fill="#FFF"
			d="M1062.3 676.7H841.9c-6.9 0-12.4-5.6-12.4-12.4 0-6.9 5.6-12.4 12.4-12.4h220.3c6.9 0 12.4 5.6 12.4 12.4.1 6.8-5.5 12.4-12.3 12.4z"
			opacity="0.38"
		></path>
		<linearGradient
			id="SVGID_27_"
			x1="368.719"
			x2="712.608"
			y1="421.391"
			y2="421.391"
			gradientUnits="userSpaceOnUse"
		>
			<stop offset="0" stopColor="#70CDE2"></stop>
			<stop offset="0.238" stopColor="#52C2D5"></stop>
			<stop offset="0.741" stopColor="#0AB"></stop>
			<stop offset="1" stopColor="#00A0AE"></stop>
		</linearGradient>
		<path
			fill="url(#SVGID_27_)"
			d="M696.3 614.4H385.1c-9 0-16.3-7.3-16.3-16.3V244.7c0-9 7.3-16.3 16.3-16.3h311.2c9 0 16.3 7.3 16.3 16.3V598c0 9.1-7.3 16.4-16.3 16.4z"
			opacity="0.27"
		></path>
		<linearGradient
			id="SVGID_28_"
			x1="739.425"
			x2="1083.314"
			y1="421.391"
			y2="421.391"
			gradientUnits="userSpaceOnUse"
		>
			<stop offset="0" stopColor="#B080B8"></stop>
			<stop offset="1" stopColor="#783B87"></stop>
		</linearGradient>
		<path
			fill="url(#SVGID_28_)"
			d="M1067 614.4H755.8c-9 0-16.3-7.3-16.3-16.3V244.7c0-9 7.3-16.3 16.3-16.3H1067c9 0 16.3 7.3 16.3 16.3V598c0 9.1-7.3 16.4-16.3 16.4z"
			opacity="0.34"
		></path>
		<g>
			<linearGradient
				id="SVGID_29_"
				x1="867.381"
				x2="839.212"
				y1="-80.873"
				y2="-31.903"
				gradientTransform="rotate(-33.17 1467.516 513.423)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#FAC1BA"></stop>
				<stop offset="1" stopColor="#FDE6E6"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_29_)"
				d="M718.9 349c-5.7-.2-11.6 5-15.4 9.6-.2-2.5-.8-5.1-3.6-4-3.8 1.6-5.1 8.9-5.5 12.4-13.7 4.1-50.4 9.3-55.2 7.1-5.9-2.7-48-26.9-51-26.9s-30.9 26.6-30.9 26.6 43.2 25.2 69.8 27c26.6 1.9 64.2-9.3 84.2-20.1 23.8-12.8 10.6-31.6 7.6-31.7z"
			></path>
			<linearGradient
				id="SVGID_30_"
				x1="133.822"
				x2="213.186"
				y1="151.341"
				y2="151.341"
				gradientTransform="scale(1 -1) rotate(-83.921 89.606 -324.908)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#B080B8"></stop>
				<stop offset="1" stopColor="#783B87"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_30_)"
				d="M591.1 395.7c-.9.5-35.2-4-57.1-41.8-2-3.5-5.4-12.5-5.5-16.5-.5-21.5 23.2-27.9 83.9 19.4 4.9.3-13 34.5-21.3 38.9z"
			></path>
			<linearGradient
				id="SVGID_31_"
				x1="724.666"
				x2="937.442"
				y1="215.641"
				y2="252.551"
				gradientTransform="scale(-1 1) rotate(1.679 -7001.968 -53350.907)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#FDDED9"></stop>
				<stop offset="1" stopColor="#FAC1BA"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_31_)"
				d="M668.6 466.5c8.7 2.7 31.1 5.5 56.4 5.1-1.9-4.1-2.8-8.6-2.3-13 .4-3.3.6-6.5.6-9.8v-3.3c0 1.1.1 2.2.1 3.3.5-.6-.2-4-.3-5 0-.5-.1-.9-.1-1.4-22.9 3.9-46.3 4.9-52.7 4.3-3.9-3.1-15.4-13.3-20.7-12.7-14.3 1.7 4.3 10.5 4.3 10.5s-6.5-5.3-15.2-9.3c-7-3.2-8.1 20.5 3 27.6 9 5.5 17.8 4.2 26.9 3.7z"
			></path>
			<path
				fill="#2F2E75"
				d="M807.7 318.7c-1.3 0-11.2 6.2-16.2 24.2-5.7 20.6-16.3 77.4-28.6 83.9-19.9 10.6-59.7 15.3-84.2 15.8-1.8-.2-5.8 26.5-.3 33.4 2.2 2.7 66.5 14.6 104.5-4.5 24.2-12.1 40.1-51.7 41.5-55.3 1.4-3.3-1.2-97.9-16.7-97.5z"
			></path>
			<linearGradient
				id="SVGID_32_"
				x1="1193.594"
				x2="1244.048"
				y1="468.706"
				y2="468.706"
				gradientTransform="matrix(-.9981 .0633 .0633 .9981 2208.556 -7.851)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#F16B8A"></stop>
				<stop offset="1" stopColor="#ED288B"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_32_)"
				d="M1044.7 546.9c0-.1 0-.1 0 0 1.7-.3 2.9-2 2.7-3.8 0-.2-2.9-22.5-27.4-19.9h-.2c-19.7 3.5-23.2 16.3-22.6 26.3.1 1.8 1.6 3.2 3.2 3.1 1.7-.2 2.9-1.8 2.8-3.6-.7-10.8 4.8-16.9 17.3-19.2 18.7-1.9 20.7 13.6 20.8 14.2.3 1.8 1.8 3.1 3.4 2.9z"
			></path>
			<linearGradient
				id="SVGID_33_"
				x1="1155.992"
				x2="1272.05"
				y1="534.739"
				y2="534.739"
				gradientTransform="matrix(-.9981 .0633 .0633 .9981 2208.556 -7.851)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#F16B8A"></stop>
				<stop offset="1" stopColor="#ED288B"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_33_)"
				d="M993.9 668.5l86-12.2c7.1-1 12.1-9.4 11.1-18.7l-8.9-85.6c-1-9.3-7.6-16.1-14.6-15.1l-86 12.2c-7.1 1-12.1 9.4-11.1 18.7l8.9 85.6c.9 9.4 7.5 16.1 14.6 15.1z"
			></path>
			<linearGradient
				id="SVGID_34_"
				x1="1217.476"
				x2="1217.476"
				y1="470.378"
				y2="541.053"
				gradientTransform="matrix(-.997 .0711 .0472 .8663 2216.675 45.107)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#F16B8A"></stop>
				<stop offset="1" stopColor="#ED288B"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_34_)"
				d="M1069.9 536l-92 13c-5.9.8-10.2 7-9.5 13.8l3.1 30.1c.7 6.7 6.1 11.6 12 10.7l92-13c5.9-.8 10.2-7 9.5-13.8l-3.1-30.1c-.6-6.7-6-11.6-12-10.7z"
			></path>
			<linearGradient
				id="SVGID_35_"
				x1="796.61"
				x2="1003.701"
				y1="71.522"
				y2="107.446"
				gradientTransform="scale(1 -1) rotate(-73.763 594.207 -230.816)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#FDDED9"></stop>
				<stop offset="1" stopColor="#FAC1BA"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_35_)"
				d="M1007.7 533.1c1.7-9.4 2.4-12.6 2.4-12.6s-6.8 14.5-9.1 6.8c-1.5-5.1 5.1-18.7 7.1-23.2-1.6-10.5-19.6-65.3-38.6-85.4-27.5-29.2-49.8-67.7-49.8-67.7l36.6-11.1s30.7 32.8 43.8 57.3c20 37.2 28.4 88.5 27.7 103.6 2.8 8.6 6.2 16.8 2.9 27-2.3 7.7-24.4 12.9-23 5.3z"
			></path>
			<path
				fill="#2F2E75"
				d="M942.1 614.3c1.2-30.4-3.7-58.2-2.6-87.2 4.8-.6-137.5 15.7-137.5 15.7s-2.3 36-2.5 71.5h142.6z"
			></path>
			<linearGradient
				id="SVGID_36_"
				x1="1807.52"
				x2="1889.723"
				y1="358.798"
				y2="358.798"
				gradientTransform="scale(-1 1) rotate(3.894 1371.375 -39571.304)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#FAC1BA"></stop>
				<stop offset="1" stopColor="#FDDED9"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_36_)"
				d="M847.5 270.6c1.4 5 40.1-10.3 40.1-10.3s-.7 39.4 19.3 66c3.8 11.3-75.5 13.3-82.4-1 2.7-1.8 18-14.4 23-54.7z"
			></path>
			<linearGradient
				id="SVGID_37_"
				x1="1771.706"
				x2="1933.395"
				y1="493.348"
				y2="493.348"
				gradientTransform="scale(-1 1) rotate(3.894 1371.375 -39571.304)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#F9F183"></stop>
				<stop offset="0.33" stopColor="#F9DC6B"></stop>
				<stop offset="1" stopColor="#F7B02F"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_37_)"
				d="M796.4 348.6c-.3-8 .4-16.3 5.6-22.5 11.8-14.2 28.7-9.8 28.8-10.1 17.5 21.3 65.1 12.4 69.9-1.2 21.1-10.8 42.6 16.8 42.6 16.8s5.9 130.2 1.9 156c-3.7 24.5-.8 42.2-11.6 48.8-37.8 31.7-127.3 12.8-130.8 5.5-3.2-5.3-14-21.6-7.5-61 5.7-34.8 2-105.3 1.1-132.3z"
			></path>
			<path
				fill="#2F2E75"
				d="M876.9 339.4l-13-11.3c-.9 1.5-17 10-17 10l8 15.2s-11 126.1-10.8 144.3c-.9 1 15.6 32.4 15.6 32.4l17.3-34.7-9.2-142.3 9.1-13.6z"
			></path>
			<linearGradient
				id="SVGID_38_"
				x1="1799.594"
				x2="1889.907"
				y1="389.156"
				y2="389.156"
				gradientTransform="scale(-1 1) rotate(3.894 1371.375 -39571.304)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#F7B02F"></stop>
				<stop offset="0.67" stopColor="#F9DC6B"></stop>
				<stop offset="1" stopColor="#F9F183"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_38_)"
				d="M865.9 322c23-.4 31.4-12.7 35.4-12.4 4 .3 10.5 1.1 12.7 3.3-7.9 23.2-31.9 34.5-31.9 34.5l-19-15.7s-15.8 10.6-19.1 12.4c-10.2-8.2-20.4-29.6-20.4-29.6l10.4-2.4c0 .1 8.9 10.4 31.9 9.9z"
			></path>
			<linearGradient
				id="SVGID_39_"
				x1="1819.211"
				x2="1885.264"
				y1="327.399"
				y2="327.399"
				gradientTransform="scale(-1 1) rotate(3.894 1371.375 -39571.304)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#FAC1BA"></stop>
				<stop offset="1" stopColor="#FDDED9"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_39_)"
				d="M890.7 262.3c-.7 32.2-36 62-56.1 45.4s2.1-75.8 2.1-75.8c16-18 54.7-1.9 54 30.4z"
			></path>
			<path
				fill="#282566"
				d="M841.3 201.5c-9.6 1.1-20 10.4-8.1 43.6-.1-.9 1-10.1 9.3-15.5 0 0 27.6-2.1 41.4 17.6 1.8 7.8 6.3 19.9 4.4 29.6 1.7-3.1 16-16.9 8-53.3-9.4-18.5-45.4-23.2-55-22z"
			></path>
			<path
				fill="#282465"
				d="M946.8 327.6c-4.1-4.6-19.1-16.9-37.8-17l-2.2 257s38.5 3.4 48.1-8.7c1.8-.6-8.4-35.6-6-79.4 3.9-70.9 1.8-141.6-2.1-151.9zM800.4 322.2c-9.2 9.9-9.2 106.1-8.9 142.2.3 36.1-18.6 80-10.8 90.4 7.8 10.4 46.2 19.8 51.1 12.4-.2-10.9-8.3-252.5-8.3-252.5s-18 1.7-23.1 7.5z"
			></path>
			<path
				fill="#2F2E75"
				d="M919.7 311c-6.4-1.2-12.6-2-12.6-2l1.1 120.9 17.5-68.4-10.7-4 13.2-1.7c-.2 0-4.8-37.4-8.5-44.8zM814 316c-2.9 5.3-7.4 44.2-7.4 44.2l12.9 2.6-10.9 2.8 18.8 62.1-3.7-114.3c0-.1-8.7 2.1-9.7 2.6z"
			></path>
			<linearGradient
				id="SVGID_40_"
				x1="1817.522"
				x2="1827.316"
				y1="330.716"
				y2="330.716"
				gradientTransform="scale(-1 1) rotate(3.894 1371.375 -39571.304)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#FDDED9"></stop>
				<stop offset="1" stopColor="#FAC1BA"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_40_)"
				d="M892.7 264.6c0 4.8-8.8 15.3-8.8 15.3l1.5-20.8c.1.1 7.2-2.4 7.3 5.5z"
			></path>
			<path
				fill="#282566"
				d="M847.2 253.9c-1.5-.8-4.2-1.7-8.1-2.6-3-.7-6.1-1.3-8.2-1.6-1.7-.3-3.4.9-3.9 2.9-.7 2.6-1.4 6.2-1.3 8.6.1 4 8 6.1 10.4 6.7 1.2.3 3.5.7 4.5.4 2.3-.6 6.3-6.8 7.2-10.5.4-1.6.3-2.9-.4-3.6l-.2-.3zm-1.7 4.4c-1.4 3.5-4.3 7.3-5.2 7.6-.2.1-1.4 0-3.6-.5-5.1-1.2-8.7-3.3-8.8-4.5-.1-1.6.4-4.4 1.2-7.8.2-.7.8-1.2 1.5-1.1 2.1.4 5.1.9 8.1 1.6 4.3 1 6.4 1.8 7.4 2.3-.1.3-.1 1-.6 2.4zM874.5 258.6c-1.3-.3-2.9-.7-4.6-1-7.7-1.7-12.9-2.5-15.5-2.4l-.4.1c-.6.2-1.5.8-1.8 2.4-.8 3.7 1.2 12.2 3 14 .9 1 3.4 1.7 5.4 2.1 1.3.3 12.4 2.6 14.5-1.4 1.1-2 2.1-5.5 2.7-8 .3-1.2.1-2.5-.5-3.6s-1.6-1.9-2.8-2.2zm1 5.1c-.9 3.4-1.8 6-2.5 7.4-.6 1.1-5.9 1.5-11.8.2-2.9-.6-4-1.2-4.2-1.4-.9-.9-2.7-7.2-2.5-10.8.1-.9.2-1.3.3-1.4 2.5 0 7.5.8 14.6 2.4 1.7.4 3.3.7 4.5 1 .6.1 1 .5 1.3 1 .3.4.4 1 .3 1.6z"
			></path>
			<path
				fill="#282566"
				d="M846.3 254.2l.1 2.5s3.1-.2 6.1 1.5l1.2-2.2c-3.7-2-7.2-1.8-7.4-1.8z"
			></path>
			<g>
				<path
					fill="#282566"
					d="M875.7 259.9l.7 2.4c3.2-1 10-3.9 12.3-3.4l-.1-.1c-3.7-1.9-11.9.8-12.9 1.1z"
				></path>
			</g>
			<g>
				<path
					fill="#282566"
					d="M876.3 283.1c-11.6 8.4-24.2 1.5-28.6-3.6-.7-.8-1.7-1.3-2.7-1.4-.4 0-1.1-.1-1.8-.2-.9-.1-1.8.1-2.5.5-3.5 2.5-12.1 2.2-14.3-5.1-11.8 23.9 1 43.2 23.7 42.5 31.6-1 36.1-41.7 36.1-41.7s-3 3.6-9.9 9z"
				></path>
			</g>
			<path
				fill="#282465"
				d="M1007.4 405.4c-18.5-41.7-60.6-77.9-60.6-77.9l-.5 70.7s22 28 26 31 27.2 62.8 30.5 70c17.1 1.3 28.7-5 28.3-5.9.7-7.3-14.8-63.9-23.7-87.9z"
			></path>
			<g>
				<linearGradient
					id="SVGID_41_"
					x1="864.613"
					x2="913.618"
					y1="8.924"
					y2="8.924"
					gradientTransform="scale(-1 1) rotate(41.777 -432.806 -1675.114)"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0" stopColor="#F9F183"></stop>
					<stop offset="0.33" stopColor="#F9DC6B"></stop>
					<stop offset="1" stopColor="#F7B02F"></stop>
				</linearGradient>
				<path
					fill="url(#SVGID_41_)"
					d="M552.6 479.9s-8.5-8.7-8-19.3c3.2-6 35.7-6.4 46-11.7 2.9 4.8 2.8 12.9.5 15.8-2.5 2.7-23.7 16.3-38.5 15.2z"
				></path>
			</g>
			<g>
				<path
					fill="#2F2E75"
					d="M584.9 614.3c-.5-48.6-5.3-113.6-5.3-113.6-15-21.5-74.1 13.2-74.1 13.2s-2.1 57 0 100.4h79.4z"
				></path>
			</g>
			<g>
				<path
					fill="#2F2E75"
					d="M520.8 614.3c4.3-41.6 6.3-96.8 6.3-96.8-13.5-22.4-77.5-6.6-77.5-6.6s-2.8 57-4.4 103.4h75.6z"
				></path>
			</g>
			<g>
				<linearGradient
					id="SVGID_42_"
					x1="1193.698"
					x2="1278.125"
					y1="4.919"
					y2="4.919"
					gradientTransform="scale(-1 1) rotate(2.235 -7273 -44889.416)"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0" stopColor="#FAC1BA"></stop>
					<stop offset="1" stopColor="#FDDED9"></stop>
				</linearGradient>
				<path
					fill="url(#SVGID_42_)"
					d="M504.9 264.7s-13.3 59.1-24.4 68.9c-3.4 11.7 77.8 10.2 84.2-4.7-2.8-1.8-19-13.9-26-54.9-1.3 5.2-33.8-9.3-33.8-9.3z"
				></path>
			</g>
			<g>
				<linearGradient
					id="SVGID_43_"
					x1="1183.622"
					x2="1246.176"
					y1="-26.493"
					y2="-26.493"
					gradientTransform="scale(-1 1) rotate(5.561 -2639.462 -17828.417)"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0" stopColor="#FDDED9"></stop>
					<stop offset="1" stopColor="#FAC1BA"></stop>
				</linearGradient>
				<path
					fill="url(#SVGID_43_)"
					d="M548.6 227.3s23.8 45.1 3.2 71.3c-15.1 19.3-54.3-11.5-55.5-41.8-1.2-30.4 37-46.3 52.3-29.5z"
				></path>
			</g>
			<g>
				<path
					fill="#2F2E75"
					d="M548.8 216.3c-1.5-3.7-15.7-17.3-14.6-14.1 1.1 3.2-8.8 13.2-8.8 13.2s.7-2.1-2.8-5c-.9 4-10.8 11.4-10.8 11.4s-.8-8.7-2.4-7c-.4 4.6-11.2 16-10.9 15.6.3-.4-3.3-4.8-4.9-6.2 1.4 4.1-2.2 15-2 21.5.8 19.1 9.2 31.6 10.1 36.1 2.5-2.5 1.1-9.9 2.6-15.1 2.5-8.7 7.8-1.5 9.6 2.9-1.1-13.2.9-16 2.3-20.3 2.1-4.4 8.4-8.9 22-11 15.6-5.1 17.5 7.4 17.5 7.4s-.7-10-.8-15.3c-.2-7.7-5.8-19.6-5.8-19.6s-.2 5.7-.3 5.5z"
				></path>
			</g>
			<g>
				<linearGradient
					id="SVGID_44_"
					x1="1177.037"
					x2="1322.804"
					y1="123.909"
					y2="123.909"
					gradientTransform="scale(-1 1) rotate(2.235 -7273 -44889.416)"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0" stopColor="#B080B8"></stop>
					<stop offset="1" stopColor="#783B87"></stop>
				</linearGradient>
				<path
					fill="url(#SVGID_44_)"
					d="M490.9 315.6s-8-2-25.9 11.2c-8.1 6-15.6 31-16.8 41-3.3 26-4.2 51.8-4.2 51.8s-7.4 91.4 3.5 104.7c80.5 12.1 134-1.6 134-1.6s15-148.5-15-193.7c-6.5-9.8-12.9-13.7-12.9-13.7s-1.3 8.5-12.8 14.2c-13 6.2-32.6 5.7-49.9-13.9z"
				></path>
			</g>
			<g>
				<path
					fill="#282465"
					d="M447.5 524.2l.9 13.9s92.4 14.9 134.4-1.1c1.6-.6-1.3-14.4-1.3-14.4s-39.3 10.6-134 1.6z"
				></path>
			</g>
			<linearGradient
				id="SVGID_45_"
				x1="577.851"
				x2="545.163"
				y1="406.148"
				y2="462.974"
				gradientTransform="rotate(-7.279 516.743 389.776)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#FAC1BA"></stop>
				<stop offset="1" stopColor="#FDE6E6"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_45_)"
				d="M659.4 443.2c-5.8-3.1-14.7-.6-21 2.2 1.1-2.7 1.8-5.7-1.7-5.9-4.8-.3-9.8 6.7-12 10.2-16.4-2.7-57.3-15.8-61.2-20.6-4.8-5.8-36.5-52.4-39.6-53.9-3.1-1.5-45.7 12.1-45.7 12.1s32.3 48.2 59.2 63.6c26.8 15.4 71.7 22.9 98.1 21.7 31.2-1.5 27-27.8 23.9-29.4z"
			></path>
			<g>
				<linearGradient
					id="SVGID_46_"
					x1="693.563"
					x2="785.655"
					y1="103.615"
					y2="103.615"
					gradientTransform="scale(-1 1) rotate(70.19 -76.231 -642.397)"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0" stopColor="#783B87"></stop>
					<stop offset="1" stopColor="#B080B8"></stop>
				</linearGradient>
				<path
					fill="url(#SVGID_46_)"
					d="M502.4 427.2c-1.2 0-34.8-22-38.4-72.6-.3-4.7.7-15.7 2.6-20 10.4-22.7 38.4-17.4 77.8 62.8 4.8 2.7-31.2 29.4-42 29.8z"
				></path>
			</g>
		</g>
		<g>
			<linearGradient
				id="SVGID_47_"
				x1="-258.72"
				x2="-103.185"
				y1="1744.312"
				y2="1744.312"
				gradientTransform="rotate(-78.235 -539.284 948.905)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#FAC1BA"></stop>
				<stop offset="1" stopColor="#FDDED9"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_47_)"
				d="M309.8 836.7s-11.8-11-28.4-3.5c3.1-8.1 28-140.6 28-140.6s10.8-20.6 29.6-.4c9 6.3-29.2 144.5-29.2 144.5z"
			></path>
			<path
				fill="#FFF"
				d="M245 758.9H390.1V759.6H245z"
				transform="rotate(-78.224 317.54 759.181)"
			></path>
			<path
				fill="#FFF"
				d="M266.1 730.8H363V732.1999999999999H266.1z"
				transform="rotate(-78.241 314.55 731.482)"
			></path>
			<path
				fill="#FFF"
				d="M252.2 748.6H379.1V749.6H252.2z"
				opacity="0.49"
				transform="rotate(-78.23 315.626 749.066)"
			></path>
			<linearGradient
				id="SVGID_48_"
				x1="1093.562"
				x2="1255.42"
				y1="1743.382"
				y2="1743.382"
				gradientTransform="scale(-1 1) rotate(78.235 1036.176 337.36)"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#70CDE2"></stop>
				<stop offset="0.238" stopColor="#52C2D5"></stop>
				<stop offset="0.741" stopColor="#0AB"></stop>
				<stop offset="1" stopColor="#00A0AE"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_48_)"
				d="M284.2 833.6l28.1-135c1.5-7.1 7.6-11.9 13.7-10.6l1.9.4c6 1.3 9.8 8.1 8.3 15.2l-28.1 135c-.3 1.4.4 2.8 1.7 3l2.4.5c1.2.3 2.4-.7 2.7-2.1l28.9-138.7c2-9.4-3-18.5-11-20.2l-8.1-1.7c-8-1.7-16.2 4.7-18.1 14.1l-28.9 138.7c-.3 1.4.4 2.8 1.7 3l2.4.5c.9.3 2.2-.6 2.4-2.1z"
			></path>
			<g>
				<linearGradient
					id="SVGID_49_"
					x1="-496.28"
					x2="-337.28"
					y1="1773.928"
					y2="1773.928"
					gradientTransform="rotate(-84.462 -586.822 837.656)"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0" stopColor="#FEECEA"></stop>
					<stop offset="1" stopColor="#FCD9D5"></stop>
				</linearGradient>
				<path
					fill="url(#SVGID_49_)"
					d="M368.9 836.9s-14.9-10.1-32.9-.7c2.4-8.5 12.7-146 12.7-146s9.6-22 33.7-3.3c11.1 5.4-13.5 150-13.5 150z"
				></path>
				<path
					fill="#FFF"
					d="M292.9 756.8H441.2V757.5999999999999H292.9z"
					transform="scale(.99996) rotate(-84.468 367.127 757.18)"
				></path>
				<path
					fill="#FFF"
					d="M310.4 728.4H409.4V730H310.4z"
					opacity="0.49"
					transform="rotate(-84.461 359.887 729.181) scale(.99997)"
				></path>
				<path
					fill="#FFF"
					d="M298.7 746.4H428.4V747.6H298.7z"
					opacity="0.49"
					transform="matrix(.0965 -.9953 .9953 .0965 -415.088 1036.754)"
				></path>
				<linearGradient
					id="SVGID_50_"
					x1="331.418"
					x2="389.066"
					y1="758.605"
					y2="758.605"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0" stopColor="#F16B8A"></stop>
					<stop offset="1" stopColor="#ED288B"></stop>
				</linearGradient>
				<path
					fill="url(#SVGID_50_)"
					d="M345.4 691.3l-14 144.2c-.1 1.5.9 2.8 2.3 2.9l2.8.3c1.4.1 2.7-1 2.8-2.4L352.9 696c.7-7.4 7.1-12.9 14.1-12.2l2.2.2c7.1.7 12.2 7.3 11.5 14.7L367.1 839c-.1 1.5.9 2.8 2.3 2.9l2.8.3c1.4.1 2.7-1 2.8-2.4l14-144.2c1-9.8-5.9-18.6-15.3-19.5l-9.5-.9c-9.4-1-17.8 6.3-18.8 16.1z"
				></path>
			</g>
			<g>
				<linearGradient
					id="SVGID_51_"
					x1="-504.318"
					x2="-327.763"
					y1="1788.683"
					y2="1788.683"
					gradientTransform="scale(-1 1) rotate(89.375 112.796 1274.152)"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0" stopColor="#F9F183"></stop>
					<stop offset="0.33" stopColor="#F9DC6B"></stop>
					<stop offset="1" stopColor="#F7B02F"></stop>
				</linearGradient>
				<path
					fill="url(#SVGID_51_)"
					d="M421.1 836.6s-15.3-9.7-32 2.4c1.6-9.6.4-162.8.4-162.8s7.5-25.3 32.5-6.9c11.1 5-.9 167.3-.9 167.3z"
				></path>
				<path
					fill="#FFF"
					d="M330.4 748.2H495.09999999999997V749H330.4z"
					opacity="0.49"
					transform="rotate(-89.369 412.793 748.622) scale(.99996)"
				></path>
				<path
					fill="#FFF"
					d="M348.5 717.6H458.5V719.1H348.5z"
					opacity="0.49"
					transform="rotate(-89.372 403.48 718.384) scale(.99996)"
				></path>
				<path
					fill="#FFF"
					d="M336.5 737.2H480.5V738.4000000000001H336.5z"
					transform="rotate(-89.371 408.476 737.763) scale(.99996)"
				></path>
				<linearGradient
					id="SVGID_52_"
					x1="-508.733"
					x2="-325.002"
					y1="1788.085"
					y2="1788.085"
					gradientTransform="scale(-1 1) rotate(89.375 112.796 1274.152)"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0" stopColor="#B080B8"></stop>
					<stop offset="1" stopColor="#783B87"></stop>
				</linearGradient>
				<path
					fill="url(#SVGID_52_)"
					d="M392.3 838.8l1.7-156.5c.1-8.2 5.8-14.9 12.7-14.9h2.1c6.9.1 12.5 6.9 12.4 15.1L419.5 839c0 1.7 1.1 3 2.5 3h2.7c1.4 0 2.5-1.3 2.5-3l1.8-160.8c.1-10.9-7.3-20-16.5-20.1l-9.3-.1c-9.2-.1-16.8 8.8-16.9 19.7l-1.8 160.8c0 1.7 1.1 3 2.5 3h2.7c1.4.3 2.5-1 2.6-2.7z"
				></path>
			</g>
		</g>
		<g>
			<linearGradient
				id="SVGID_53_"
				x1="876.055"
				x2="896.165"
				y1="715.416"
				y2="715.416"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#70CDE2"></stop>
				<stop offset="0.238" stopColor="#52C2D5"></stop>
				<stop offset="0.741" stopColor="#0AB"></stop>
				<stop offset="1" stopColor="#00A0AE"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_53_)"
				d="M888.5 755.8L876.1 754.4 883.7 675.1 896.2 676.4z"
			></path>
			<linearGradient
				id="SVGID_54_"
				x1="883.707"
				x2="896.146"
				y1="667.196"
				y2="667.196"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#70CDE2"></stop>
				<stop offset="0.238" stopColor="#52C2D5"></stop>
				<stop offset="0.741" stopColor="#0AB"></stop>
				<stop offset="1" stopColor="#00A0AE"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_54_)"
				d="M894 666.1l-1.7-8.4c-.1.2-.4.4-.7.3-.3 0-.4-.2-.5-.3l-3.2 7.7-4.1 9.8 6.2.7 6.2.7-2.2-10.5z"
			></path>
			<path
				fill="#2F2E75"
				d="M891.2 678.5l-2.6-.2c-3.3-.3-6.3 2.2-6.6 5.5l-3 31.1c-.3 3.3 2.1 6.3 5.5 6.6l2.6.2c3.3.3 6.3-2.2 6.6-5.5l3-31.1c.3-3.3-2.2-6.3-5.5-6.6zM892 655.6c-.9-.1-1 1.5-1 1.9 0 .4.3.7.7.8.4 0 .8-.3.8-.7 0-.4.5-1.8-.5-2z"
			></path>
			<linearGradient
				id="SVGID_55_"
				x1="882.589"
				x2="923.901"
				y1="738.015"
				y2="738.015"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#F9F183"></stop>
				<stop offset="0.33" stopColor="#F9DC6B"></stop>
				<stop offset="1" stopColor="#F7B02F"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_55_)"
				d="M894.2 777.5L882.6 772.9 912.3 698.6 923.9 703.1z"
			></path>
			<linearGradient
				id="SVGID_56_"
				x1="920.696"
				x2="921.109"
				y1="693.741"
				y2="693.741"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#FAC1BA"></stop>
				<stop offset="1" stopColor="#FDDED9"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_56_)"
				d="M921.1 693.9L920.9 693.9 920.8 693.9 920.8 693.8 920.7 693.7 920.8 693.7 920.9 693.5 921 693.7 921.1 693.7 921 693.8z"
			></path>
			<linearGradient
				id="SVGID_57_"
				x1="919.915"
				x2="920.269"
				y1="695.66"
				y2="695.66"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#FAC1BA"></stop>
				<stop offset="1" stopColor="#FDDED9"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_57_)"
				d="M920.2 695.5L920.2 695.7 920.3 695.8 920.1 695.8 919.9 695.7 920.1 695.6z"
			></path>
			<linearGradient
				id="SVGID_58_"
				x1="912.245"
				x2="925.683"
				y1="692.697"
				y2="692.697"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#FAC1BA"></stop>
				<stop offset="1" stopColor="#FDDED9"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_58_)"
				d="M923.8 703.4L918 701 912.2 698.7 919 690.4 925.7 682 924.8 692.7z"
			></path>
			<linearGradient
				id="SVGID_59_"
				x1="921.841"
				x2="925.707"
				y1="685.04"
				y2="685.04"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#F9F183"></stop>
				<stop offset="0.33" stopColor="#F9DC6B"></stop>
				<stop offset="1" stopColor="#F7B02F"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_59_)"
				d="M925.2 688.1L923.5 687.5 921.8 686.8 923.8 684.4 925.7 682 925.4 685z"
			></path>
			<g>
				<linearGradient
					id="SVGID_60_"
					x1="842.058"
					x2="874.723"
					y1="742.642"
					y2="742.642"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0" stopColor="#B080B8"></stop>
					<stop offset="1" stopColor="#783B87"></stop>
				</linearGradient>
				<path
					fill="url(#SVGID_60_)"
					d="M874.7 775.8L862.6 777.7 842.1 709.5 854.1 707.6z"
				></path>
				<linearGradient
					id="SVGID_61_"
					x1="845.9"
					x2="846.315"
					y1="702.057"
					y2="702.057"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0" stopColor="#FAC1BA"></stop>
					<stop offset="1" stopColor="#FDDED9"></stop>
				</linearGradient>
				<path
					fill="url(#SVGID_61_)"
					d="M846.3 702.1L846.2 702.1 846.1 702.2 846 702.1 845.9 702.1 846 702 846 701.9 846.1 702 846.2 701.9 846.2 702z"
				></path>
				<linearGradient
					id="SVGID_62_"
					x1="846.466"
					x2="846.835"
					y1="703.776"
					y2="703.776"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0" stopColor="#FAC1BA"></stop>
					<stop offset="1" stopColor="#FDDED9"></stop>
				</linearGradient>
				<path
					fill="url(#SVGID_62_)"
					d="M846.6 703.6L846.7 703.7 846.8 703.9 846.7 703.9 846.5 703.9 846.5 703.8z"
				></path>
				<linearGradient
					id="SVGID_63_"
					x1="842.109"
					x2="854.198"
					y1="701.341"
					y2="701.341"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0" stopColor="#FAC1BA"></stop>
					<stop offset="1" stopColor="#FDDED9"></stop>
				</linearGradient>
				<path
					fill="url(#SVGID_63_)"
					d="M848.6 699.5l-4.4-6.4h-.3c-.3 0-.7-.1-.8-.1h-.2l-.3 7.4-.4 9.2 6-1 6-1-5.6-8.1z"
				></path>
				<linearGradient
					id="SVGID_64_"
					x1="842.69"
					x2="846.173"
					y1="694.762"
					y2="694.762"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0" stopColor="#B080B8"></stop>
					<stop offset="1" stopColor="#783B87"></stop>
				</linearGradient>
				<path
					fill="url(#SVGID_64_)"
					d="M844.5 693.6l-.3-.5h-.3c-.3 0-.7-.1-.8-.1h-.2v.8l-.1 2.7 1.7-.3 1.7-.3-1.7-2.3z"
				></path>
			</g>
			<linearGradient
				id="SVGID_65_"
				x1="846.209"
				x2="915.22"
				y1="791.023"
				y2="791.023"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#F16B8A"></stop>
				<stop offset="1" stopColor="#ED288B"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_65_)"
				d="M912.8 739.4h-64.2c-1.5 1.6-2.4 3.7-2.4 6.1v88.1c0 5 4.2 9.1 9.3 9.1h50.4c5.1 0 9.3-4.1 9.3-9.1v-88.1c0-2.4-.9-4.5-2.4-6.1z"
			></path>
			<linearGradient
				id="SVGID_66_"
				x1="842.265"
				x2="919.163"
				y1="742.208"
				y2="742.208"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#ED288B"></stop>
				<stop offset="1" stopColor="#F16B8A"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_66_)"
				d="M913.9 747.5h-66.4c-2.9 0-5.2-2.4-5.2-5.2 0-2.9 2.4-5.2 5.2-5.2h66.4c2.9 0 5.2 2.4 5.2 5.2.1 2.8-2.3 5.2-5.2 5.2z"
			></path>
		</g>
		<g>
			<linearGradient
				id="SVGID_67_"
				x1="302.193"
				x2="363.835"
				y1="463.078"
				y2="463.078"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#F9F183"></stop>
				<stop offset="0.33" stopColor="#F9DC6B"></stop>
				<stop offset="1" stopColor="#F7B02F"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_67_)"
				d="M363.8 463.1c0 18.4-13.8 33.4-30.8 33.4s-30.8-14.9-30.8-33.4c0-18.4 13.8-33.4 30.8-33.4s30.8 14.9 30.8 33.4z"
			></path>
			<path
				fill="#2F2E75"
				d="M87.3 568.1L160.6 806.5 174.2 801.9 100.9 563.4z"
			></path>
			<linearGradient
				id="SVGID_68_"
				x1="216.456"
				x2="236.098"
				y1="795.405"
				y2="795.405"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#F9F183"></stop>
				<stop offset="0.33" stopColor="#F9DC6B"></stop>
				<stop offset="1" stopColor="#F7B02F"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_68_)"
				d="M236.1 795.4c0 7.8-4.4 14.1-9.8 14.1s-9.8-6.3-9.8-14.1 4.4-14.1 9.8-14.1 9.8 6.3 9.8 14.1z"
			></path>
			<path
				fill="#2F2E75"
				d="M248.5 841.5c4.3 0 7.4-4 6.5-8.2-6.7-31-35-54.2-68.8-54.2s-62.1 23.2-68.8 54.2c-.9 4.2 2.2 8.2 6.5 8.2h124.6z"
			></path>
			<path
				fill="#2F2E75"
				d="M91 562.8L101.2 573.5 268.5 395.9 258.3 385.2z"
			></path>
			<linearGradient
				id="SVGID_69_"
				x1="84.699"
				x2="108.613"
				y1="570.039"
				y2="570.039"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#70CDE2"></stop>
				<stop offset="0.238" stopColor="#52C2D5"></stop>
				<stop offset="0.741" stopColor="#0AB"></stop>
				<stop offset="1" stopColor="#00A0AE"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_69_)"
				d="M108.6 570c0 6.7-5.4 12.2-12 12.2s-12-5.5-12-12.2c0-6.7 5.4-12.2 12-12.2 6.7.1 12 5.5 12 12.2z"
			></path>
			<linearGradient
				id="SVGID_70_"
				x1="249.117"
				x2="287.398"
				y1="386.541"
				y2="386.541"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#70CDE2"></stop>
				<stop offset="0.238" stopColor="#52C2D5"></stop>
				<stop offset="0.741" stopColor="#0AB"></stop>
				<stop offset="1" stopColor="#00A0AE"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_70_)"
				d="M287.4 386.5c0 10.7-8.6 19.3-19.1 19.3-10.6 0-19.1-8.7-19.1-19.3 0-10.7 8.6-19.3 19.1-19.3 10.5 0 19.1 8.7 19.1 19.3z"
			></path>
			<g fill="#2F2E75">
				<path d="M258.8 411.1c8.4 9.8 23.4 10 33.6.5 10.2-9.6 11.7-25.3 3.6-35.3l-37.2 34.8z"></path>
				<path d="M363.6 419.5c-23.5-28.5-59.1-36.4-79.5-17.6-20.4 18.8-17.9 57.2 5.5 85.7 4.1 5 8.6 9.4 13.4 13.1 2.6 2.1 6.4 1.9 8.8-.4l61.1-56.2c2.3-2.1 2.8-5.4 1.5-8.1-2.9-5.8-6.5-11.3-10.8-16.5z"></path>
			</g>
		</g>
		<g>
			<linearGradient
				id="SVGID_71_"
				x1="1220.389"
				x2="1394.082"
				y1="706.871"
				y2="706.871"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#70CDE2"></stop>
				<stop offset="0.238" stopColor="#52C2D5"></stop>
				<stop offset="0.741" stopColor="#0AB"></stop>
				<stop offset="1" stopColor="#00A0AE"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_71_)"
				d="M1383 610.5c-8.3-6.8-19.8-9-30.3-7.2-18 3.2-32.4 16.9-44.8 30.7-12.4 13.8-24.5 28.8-41.3 36.3-13 5.8-28.7 7.1-38.1 17.9-9.8 11.3-8.7 28.2-7 43.2 2.3 20.1 4.6 40.1 7.1 60.2 1 7.7 3.2 17.1 10.6 19.1 6.4 1.8 12.6-3.4 17.4-8.1 15-14.9 30-29.7 45-44.6 6.4-6.3 13.2-13.6 13.3-22.7.1-10.2-8.3-18.5-9.9-28.5-2.2-13.4 8.6-25.9 20.7-31.5 12.1-5.6 25.8-6.6 38.5-10.4 12.8-3.8 25.8-12 29.1-25.2 2.7-10.6-1.9-22.3-10.3-29.2z"
			></path>
			<linearGradient
				id="SVGID_72_"
				x1="1082.226"
				x2="1243.253"
				y1="755.547"
				y2="755.547"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#F16B8A"></stop>
				<stop offset="1" stopColor="#ED288B"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_72_)"
				d="M1170.3 707.7c-17.6-8.7-39.5-6.3-55.9 4.6-16.4 10.8-27.3 29.3-31 48.9-2.3 12.4-1.6 26.2 5.7 36.3 7.4 10.1 22.9 14.5 32.6 6.7 12-9.7 9.2-30.7 20.7-41 10.1-9.1 26.5-5 36.9 3.7 10.4 8.7 17.4 21 27.3 30.3 9.9 9.3 25.1 15.4 36.7 8.5-11.4-18.5-22.8-37-34.3-55.5-10.3-16.6-21.4-34-38.7-42.5z"
			></path>
			<path
				fill="#FFF"
				d="M1242.1 783.1c-1 3.8-2 7.4-2.4 8 .1-.1.3-.3.8-.3v2.8c1.7 0 2.1-1.5 4.3-9.7 7.4-27.4 29.9-110.6 114.8-146.6l-1.1-2.6c-86.2 36.4-108.9 120.7-116.4 148.4z"
			></path>
			<path
				fill="#FFF"
				d="M1256.3 743.8c-1.2-16.4-3.3-44.9-3.3-47h-2.8c0 2.9 3.3 47.2 3.5 49.1l.1 1.9 45.5-17-1-2.7-42 15.7z"
			></path>
			<path
				fill="#FFF"
				d="M1303.3 671.9L1321.6 636.4 1319.1 635 1297.1 677.6 1344.5 656.9 1343.4 654.3z"
			></path>
			<path
				fill="#FFF"
				d="M1152 727.3c-7.1-2-13.6-1.3-19.5 2.1-15.8 9.3-22.7 36.4-25.7 47.9-.6 2.3-1.2 4.7-1.4 5.1l1.1.9-1-1 2 2c.6-.6.9-1.8 2.1-6.3 2.9-11.2 9.5-37.5 24.4-46.2 5.2-3.1 11-3.7 17.4-1.9 33.8 9.7 66.3 55.8 66.6 56.2l2.3-1.6c-1.6-1.8-33.5-47.2-68.3-57.2z"
			></path>
			<g>
				<path
					fill="#2F2E75"
					d="M1228.4 499l-1.6-3.6c-24.9 11.3-43 40-46.1 73.1-2.9 30.7 5.9 61.7 15 87.9 1.9 5.6 4 11.3 6 16.9 7.8 21.9 16 44.5 19.5 67.8 2.9 19.2 4.7 55.5-16.2 83.5l3.1 2.4c16.1-21.6 22.1-52.3 17-86.5-3.6-23.7-11.8-46.5-19.7-68.6l-6-16.8c-8.9-25.8-17.6-56.3-14.8-86.2 3-31.8 20.2-59.2 43.8-69.9z"
				></path>
				<path
					fill="#2F2E75"
					d="M1216.4 503.8s30.9-4.2 21.9-13.8c-9.1-9.6-21.9 13.8-21.9 13.8zM1197.2 524.4s32.2 8.3 27-4.9c-5.2-13.2-27 4.9-27 4.9zM1245.7 746.7c-10.6-9.1-21.6 17.2-21.6 17.2s32.2-8.1 21.6-17.2z"
				></path>
				<path
					fill="#2F2E75"
					d="M1190.1 497.7c-12 7.1 8.5 26.6 8.5 26.6s3.5-33.7-8.5-26.6zM1199 753.3c-2.3 14 25.5 10.2 25.5 10.2s-23.3-24.2-25.5-10.2zM1157.9 534.1c-9.4 16 26.4 25.2 26.4 25.2s-17-41.2-26.4-25.2zM1187.2 718.3c-2.8 18.5 33.7 13.2 33.7 13.2s-30.9-31.7-33.7-13.2zM1135.9 583.7c-4.4 26.6 48.5 19.6 48.5 19.6s-44.2-46.2-48.5-19.6zM1146.6 627.4c-4.4 26.6 48.5 19.6 48.5 19.6s-44.1-46.2-48.5-19.6zM1163 674.5c-4.4 26.6 48.5 19.6 48.5 19.6s-44.2-46.2-48.5-19.6z"
				></path>
				<path
					fill="#2F2E75"
					d="M1183.8 603.1s62.9-6.3 45.3-26.4c-17.6-20.2-45.3 26.4-45.3 26.4zM1193.2 646.7s62.9-6.3 45.3-26.4c-17.6-20.1-45.3 26.4-45.3 26.4zM1245 654.9c-22.9-13.5-34.9 39.6-34.9 39.6s57.7-26.1 34.9-39.6zM1183 559s43.6 3.9 34.1-12.1S1183 559 1183 559zM1242.4 701.8c-16.7-7.6-21.2 29.9-21.2 29.9s37.9-22.3 21.2-29.9z"
				></path>
			</g>
			<g>
				<linearGradient
					id="SVGID_73_"
					x1="1180.341"
					x2="1284.086"
					y1="809.633"
					y2="809.633"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0" stopColor="#F9F183"></stop>
					<stop offset="0.33" stopColor="#F9DC6B"></stop>
					<stop offset="1" stopColor="#F7B02F"></stop>
				</linearGradient>
				<path
					fill="url(#SVGID_73_)"
					d="M1180.3 777.7c.7 12.1 4.1 41.1 20.9 63.9h69.7c1.9-4.7 10.6-28.7 13.1-63.9h-103.7z"
				></path>
			</g>
		</g>
		<g opacity="0.42">
			<linearGradient
				id="SVGID_74_"
				x1="878.491"
				x2="1180.891"
				y1="82.979"
				y2="82.979"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#F16B8A"></stop>
				<stop offset="1" stopColor="#ED288B"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_74_)"
				d="M1162.6 18.5H903.5c-10.1 0-18.3 8.2-18.3 18.3v76.4c0 3.1.8 5.9 2.1 8.5l-8.8 25.7 23.2-16c.6.1 1.2.1 1.8.1h259.1c10.1 0 18.3-8.2 18.3-18.3V36.8c0-10.1-8.2-18.3-18.3-18.3z"
			></path>
			<path
				fill="#FFF"
				d="M1155.1 53.3H953.4c-3.2 0-5.8-2.6-5.8-5.8v-1.2c0-3.2 2.6-5.8 5.8-5.8h201.7c3.2 0 5.8 2.6 5.8 5.8v1.2c0 3.2-2.6 5.8-5.8 5.8zM1155.1 81.5H953.4c-3.2 0-5.8-2.6-5.8-5.8v-1.2c0-3.2 2.6-5.8 5.8-5.8h201.7c3.2 0 5.8 2.6 5.8 5.8v1.2c0 3.3-2.6 5.8-5.8 5.8zM1155.1 110.3H953.4c-3.2 0-5.8-2.6-5.8-5.8v-1.2c0-3.2 2.6-5.8 5.8-5.8h201.7c3.2 0 5.8 2.6 5.8 5.8v1.2c0 3.2-2.6 5.8-5.8 5.8zM930.3 49.4c0 4.7-3.8 8.6-8.6 8.6-4.7 0-8.6-3.8-8.6-8.6 0-4.7 3.8-8.6 8.6-8.6 4.7 0 8.6 3.9 8.6 8.6zM930.3 76c0 4.7-3.8 8.6-8.6 8.6-4.7 0-8.6-3.8-8.6-8.6 0-4.7 3.8-8.6 8.6-8.6 4.7 0 8.6 3.9 8.6 8.6zM930.3 103.7c0 4.7-3.8 8.6-8.6 8.6-4.7 0-8.6-3.8-8.6-8.6 0-4.7 3.8-8.6 8.6-8.6 4.7 0 8.6 3.8 8.6 8.6z"
			></path>
		</g>
		<g opacity="0.37">
			<linearGradient
				id="SVGID_75_"
				x1="203.568"
				x2="505.968"
				y1="100.696"
				y2="100.696"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#00A0AE"></stop>
				<stop offset="0.259" stopColor="#0AB"></stop>
				<stop offset="0.762" stopColor="#52C2D5"></stop>
				<stop offset="1" stopColor="#70CDE2"></stop>
			</linearGradient>
			<path
				fill="url(#SVGID_75_)"
				d="M221.8 36.3h259.1c10.1 0 18.3 8.2 18.3 18.3V131c0 3.1-.8 5.9-2.1 8.5l8.8 25.7-23.2-16c-.6.1-1.2.1-1.8.1H221.8c-10.1 0-18.3-8.2-18.3-18.3V54.5c.1-10.1 8.2-18.2 18.3-18.2z"
			></path>
			<path
				fill="#FFF"
				d="M461.7 72.7h-174c-3.3 0-6-2.7-6-6v-1.3c0-3.3 2.7-6 6-6h174c3.3 0 6 2.7 6 6v1.3c0 3.3-2.7 6-6 6zM461.7 101.5h-174c-3.3 0-6-2.7-6-6v-1.3c0-3.3 2.7-6 6-6h174c3.3 0 6 2.7 6 6v1.3c0 3.3-2.7 6-6 6zM461.7 129.1h-174c-3.3 0-6-2.7-6-6v-1.3c0-3.3 2.7-6 6-6h174c3.3 0 6 2.7 6 6v1.3c0 3.3-2.7 6-6 6zM262.8 54.8c-1-1-2.6-1-3.5 0l-16.7 16.7-8-8c-1-1-2.6-1-3.5 0-1 1-1 2.6 0 3.5l9.8 9.8c.5.5 1.1.7 1.8.7.6 0 1.3-.2 1.8-.7L263 58.3c.7-.9.7-2.5-.2-3.5zM262.8 80.3c-1-1-2.6-1-3.5 0L242.5 97l-8-8c-1-1-2.6-1-3.5 0-1 1-1 2.6 0 3.5l9.8 9.8c.5.5 1.1.7 1.8.7.6 0 1.3-.2 1.8-.7l18.5-18.5c.8-.9.8-2.5-.1-3.5zM262.8 106.9c-1-1-2.6-1-3.5 0l-16.7 16.7-8-8c-1-1-2.6-1-3.5 0-1 1-1 2.6 0 3.5l9.8 9.8c.5.5 1.1.7 1.8.7.6 0 1.3-.2 1.8-.7l18.5-18.5c.7-1 .7-2.5-.2-3.5z"
			></path>
		</g>
	</svg>
);
export default Graphic;
