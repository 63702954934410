import React from "react";
import globalHook from "use-global-hook";

import * as actions from "../actions/Furioos";

const initialState = {
	message: "",
};

const useFurioos = globalHook(React, initialState, actions);

export default useFurioos;
