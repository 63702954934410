import React, { useState, useEffect } from "react";

import iconLock from "../assets/img/icon-lock.png";
import iconUser from "../assets/img/icon-user.png";

import { LOGIN_STATES } from "../store/User";

const LoginForm = ({
	showRelogin,
	handleLogin,
	handleReLogin,
	loginState,
	className,
}) => {
	const [user, setUser] = useState("");
	const handleUserChange = (e) => {
		e.preventDefault();

		setUser(e.target.value);
	};

	const [pass, setPass] = useState("");
	const handlePassChange = (e) => {
		e.preventDefault();

		setPass(e.target.value);
	};
	const profiles = [
		{ user: "0", name: "Aubree Cruz", pass: "xxxx" },
		{ user: "1", name: "Gavin Gardner", pass: "xxxx" },
		{ user: "2", name: "Ann Medina", pass: "xxxx" },
		{ user: "3", name: "Edith Watson", pass: "xxxx" },
		{ user: "4", name: "Angel George", pass: "xxxx" },
		{ user: "5", name: "Alan Miller", pass: "xxxx" },
		{ user: "6", name: "Terry Krin", pass: "xxxx" },
		{ user: "7", name: "Gregory May", pass: "xxxx" },
		{ user: "8", name: "Holly Mitchell", pass: "xxxx" },
		{ user: "9", name: "Claude Fowler", pass: "xxxx" },
	];
	const handleProfileChange = (e) => {
		const profile = profiles.find((p) => p.user === e.target.value);
		setUser(profile.user);
		setPass(profile.pass);
	};

	return (
		<>
			<div className="mb-10 md:mb-28">
				<h1 className="font-serif text-4xl mb-7 text-black">Welcome!</h1>
				<p className="font-sans text-base text-black">
					Enter the email address used to register for the event you wish to
					join.
				</p>
			</div>

			{loginState === LOGIN_STATES.NOT_FOUND && (
				<p className="border border-primary p-2 bg-primary bg-opacity-50">
					User not found.
				</p>
			)}
			{loginState === LOGIN_STATES.ERROR && (
				<p className="border border-primary p-2 bg-primary bg-opacity-50">
					And error has occurred. Please try again later.
				</p>
			)}
			<div className="flex pl-4 mb-2">
				<div className="w-8 z-10 text-center pointer-events-none flex items-center justify-center">
					<img src={iconUser} />
				</div>
				<input
					type="text"
					className="w-full -ml-12 -mr-4 pl-14 pr-3 py-4 rounded-full border-2 border-blue-50 outline-none focus:border-blue-200 text-sm"
					placeholder="email address"
					value={user}
					onChange={handleUserChange}
				/>
			</div>
			<div className="flex pl-4 mb-2">
				<div className="w-8 z-10 text-center pointer-events-none flex items-center justify-center">
					<img src={iconLock} />
				</div>
				<input
					type="text"
					className="w-full -ml-12 -mr-4 pl-14 pr-3 py-4 rounded-full border-2 border-blue-50 outline-none focus:border-blue-200 text-sm"
					placeholder="access code"
					value={pass}
					onChange={handlePassChange}
				/>
			</div>

			{/* 
			<div className="flex pl-4 mb-2">
			<p>Or select one of the predefined profiles (temporary!)</p> */}
			{/* <select className="block w-full" onChange={handleProfileChange}>
					{profiles.map((p, idx) => (
						<option key={idx} value={p.user}>
							{p.name}
						</option>
					))}
				</select>
				</div> */}
			<div className="pr-4">
				<button
					className="bg-indigo-900 text-white text-center
							 block shadow py-4 w-full rounded-full
							 focus:ring-2 focus:ring-blue-600"
					onClick={() => handleLogin(user, pass)}
				>
					login
				</button>
			</div>
		</>
	);
};
export default LoginForm;
