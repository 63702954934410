import React, { useState, useEfect, useEffect } from "react";
import AppRouter from "./routers/AppRouter";
import "./assets/styles/main.css";
import useUser from "./store/User";

function App(props) {
	return <AppRouter {...props} />;
}

export default App;
