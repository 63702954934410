import React, { useState, useEffect, useRef, memo } from "react";
import { Player } from "furioos-sdk";
import BaseModal from "./modals/BaseModal";
import { Loading } from "./Icons";

import useUser from "../store/User";
import useFurioos from "../store/Furioos";

const FurioosPlayer = (props) => {
	const [message, setMessage] = useState("");
	const [user, userActions] = useUser();
	const [furioos, furioosActions] = useFurioos();
	const [player, setPlayer] = useState({});
	const [loadStep, setLoadStep] = useState("CONNECTING");

	const [playerLoading, setPlayerLoading] = useState(true);
	const [loadingPercentage, setLoadingPercentage] = useState(0);

	const playerRef = useRef(player);

	const FurioosId = user.config.furioosId;

	useEffect(() => {
		const options = {
			whiteLabel: true,
			hideToolbar: true,
			hideTitle: true,
			hidePlayButton: false,
			// overridedURL: "http://localhost:3000",
		};

		const furioosPlayer = new Player(FurioosId, "furioos", options);
		setPlayer(furioosPlayer);
		playerRef.current = furioosPlayer;

		furioosPlayer.onLoad(() => {
			console.log("REACT: Player is loaded", player);
			// setPlayerReady(true);
			playerRef.current.getServerAvailability(
				(e) => {
					console.log("getServerAvailability success", e);
					if (
						e.hasOwnProperty("maximumMachines") &&
						e.hasOwnProperty("usedMachines")
					) {
						if (e.usedMachines < e.maximumMachines) {
							startPlayer();
						} else {
							setLoadStep("UNAVAILABLE");
						}
					} else {
						startPlayer();
					}
					/*
					if (e.availableMachines == 0) {
						setLoadStep("UNAVAILABLE");
					} else {
						startPlayer();
					}
					*/
				},
				(e) => {
					console.log("getServerAvailability error", e);
				}
			);
			//
		});

		furioosPlayer.onStreamStart((data) => {
			setPlayerLoading(false);
			sendMessageToPlayer(`EVENT_JOINED:${user.identity.id}`);
		});

		furioosPlayer.onSDKMessage((data) => {
			setPlayerLoading(false);
			// console.log("MESSAGE FROM FURIOOS", data);

			if (data == "READY")
				sendMessageToPlayer(`EVENT_JOINED:${user.identity.id}`);

			if (data.indexOf("EXT-LINK") > -1) {
				if (data.indexOf("virtualshowfloor.cloud") > -1) {
					document.location = data.replace("EXT-LINK:", "");
				} else {
					window.open(data.replace("EXT-LINK:", ""));
				}
			}
		});
		//Bind a callback that will be called during your application installation. You'll receive the progress of the installation.
		furioosPlayer.onAppInstallProgress(function (data) {
			// Implement your own code.

			console.log(
				"REACT: " +
					data.status +
					" the application : " +
					Math.round(data.progress * 100) +
					"%"
			);

			setLoadingPercentage(Math.round(data.progress * 100));
		});
		// Bind a callback that will be called when your application installation has succeed
		furioosPlayer.onAppInstallSuccess(function () {
			// Implement your own code.
			setPlayerLoading(false);
			console.log("REACT: My application is fully installed");
			sendMessageToPlayer(`EVENT_JOINED:${user.identity.id}`);
		});
		//Bind a callback that will be called when your application installation has failed.
		furioosPlayer.onAppInstallFail(function () {
			// Implement your own code.
			console.log("REACT: Installation has failed");
		});
		//Bind a callback that will be called when your application starts.
		furioosPlayer.onAppStart(function () {
			// Implement your own code.
			setPlayerLoading(false);
			console.log("REACT: Application started");
			sendMessageToPlayer(`EVENT_JOINED:${user.identity.id}`);
		});
		// Bind a callback that will be called when the user is **inactive** on your session (only fired when a session is running).
		furioosPlayer.onUserInactive(function () {
			// Implement your own code.
			console.log("REACT: My user is inactive");

			// Check if player is not in video mode and otherwise
			// if (window.location.pathname.indexOf("video") == -1)
			// playerRef.current.setUserActive();
		});
		// Bind a callback that will be called when the session is stopped (ex: stopped for inactivity).
		furioosPlayer.onSessionStopped(function () {
			// Implement your own code.
			console.log("REACT: The session has been stopped");
		});
	}, []);
	useEffect(() => {
		if (furioos.message != "") {
			if (furioos.message == "RESTART") {
				playerRef.current.start();
				setLoadingPercentage(0);
				setLoadStep("LOADING");
				setPlayerLoading(true);
			} else if (furioos.message == "STOP") {
				playerRef.current.stop();
			} else {
				sendMessageToPlayer(furioos.message);
			}
			furioosActions.clearMessage();
		}
	}, [furioos]);
	const startPlayer = () => {
		setLoadStep("LOADING");
		playerRef.current.start();
	};
	const sendMessageToPlayer = (msg) => {
		if (typeof playerRef != "undefined" && playerRef.hasOwnProperty("current"))
			playerRef.current.sendSDKMessage(msg);

		console.log("SENDING TO PLAYER");

		// HACK ALERT
		// We have to call this function directly because sendSDKMessage
		// rejects because the player never gets into 'loaded' state
		/*
		player.embed.contentWindow.postMessage(
			{
				type: "sendSDKMessage",
				value: msg,
			},
			"https://portal.furioos.com"
		);
		*/
	};

	useEffect(() => {
		// console.log("User state updated", user);
		/*
		if (user.identity && user.identity.id !== 0) {
			sendMessageToPlayer(`EVENT_JOINED:${user.identity.id}`);
		}
		*/
	}, [user]);

	const updateMessage = (e) => {
		e.preventDefault();
		setMessage(e.target.value);
	};
	const sendMessage = (e) => {
		e.preventDefault();

		sendMessageToPlayer(message);
	};
	const SendMockMessage = () => {
		sendMessageToPlayer("Testing: " + new Date().getTime());
	};
	return (
		<div className="h-screen">
			<div id="furioos" className="h-screen"></div>

			<div className={`player-overlay ${playerLoading ? "" : "hidden"}`}>
				<BaseModal Icon={Loading} iconClass="spin" showClose={false}>
					{loadStep == "CONNECTING" && (
						<p className="font-normal text-sm">
							We're connecting you to the event environment.
							<br />
							This can take up to a minute, please stand-by
						</p>
					)}
					{loadStep == "LOADING" && (
						<p className="font-normal text-sm">
							Loading the experience
							<span className="font-bold my-2 block text-2xl">
								{loadingPercentage + "%"}
							</span>
						</p>
					)}
					{loadStep == "UNAVAILABLE" && (
						<p className="font-normal text-sm">
							Unfortunately all available sessions are occupied.
							<br />
							Try again later or use one of the{" "}
							<a href="https://accenture.oggievents.com/select-experience/">
								alternative ways to log into Oggi
							</a>
						</p>
					)}
				</BaseModal>
			</div>
			{/* {
				<div className="fixed z-50 bg-white p-10 text-gray-900 top-0 left-0 mt-32 ml-10">
					{ <button onClick={startPlayer}>Send</button> }
				</div>
			} */}
		</div>
	);
};
FurioosPlayer.whyDidYouRender = true;
export default memo(FurioosPlayer, (prevProps, nextProps) => {
	console.log("FURIOOS PLAYER", prevProps, nextProps);
	return true;
});
